import * as React from "react"
import '../styles/global.scss';
import Layout from "../components/layout";

export default class PrivacyPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={false}>
                <div className={'row mt-4'}>
                    <div className={'col-12'}>
                        <h2>Polityka prywatności aplikacji mobilnej</h2>

                        <p>Loggy sp.z.o.o. jest twórcą aplikacji mobilnej <strong>Loggy - aplikcja dla
                            kierowców</strong>, przeznaczonej do użytku komercyjnego w obecnej postaci</p>
                        <p>
                            Ta strona służy do informowania odwiedzających o naszych zasadach dotyczących
                            gromadzenia, wykorzystywania i ujawniania danych osobowych, jeśli ktoś zdecyduje się
                            skorzystać z naszej usługi. </p>
                        <p>
                            Jeśli zdecydujesz się skorzystać z naszej Usługi, wyrażasz zgodę na gromadzenie i
                            wykorzystywanie informacji w związku z niniejszymi zasadami. Gromadzone przez nas
                            Dane osobowe służą do świadczenia i ulepszania Usługi. Nie będziemy wykorzystywać
                            ani udostępniać twoich informacji nikomu, z wyjątkiem przypadków opisanych w
                            niniejszej Polityce prywatności.</p>

                        <p>Warunki użyte w niniejszej Polityce prywatności mają takie samo znaczenie jak w
                            regulaminie, który są dostępny na stronie <a
                                href="https://loggy.pl/regulamin">loggy.pl/regulamin</a>, chyba że niniejsza
                            Polityka prywatności stanowi inaczej.</p>

                        <h4>Gromadzenie i wykorzystanie informacji</h4>

                        <p>Dla lepszego doświadczenia podczas korzystania z naszej Usługi możemy wymagać od
                            Ciebie podania pewnych danych osobowych, w tym między innymi lokalizacji, numeru
                            telefonu, imię i nazwisko. Informacje, o które prosimy,
                            zostaną przez nas zachowane i wykorzystane zgodnie z opisem w niniejszej polityce
                            prywatności.</p>

                        <p>Aplikacja korzysta z usług stron trzecich, które mogą gromadzić informacje
                            wykorzystywane do identyfikacji użytkownika.</p>

                        <p>Link do polityki prywatności zewnętrznych dostawców usług wykorzystywanych przez
                            aplikację:</p>
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/">Usługi Google Play</a></li>
                        </ul>

                        <h4>Logi danych</h4>

                        <p>
                            Chcemy Cię poinformować, że za każdym razem, gdy korzystasz z naszej Usługi, w
                            przypadku
                            błędu w aplikacji zbieramy dane i informacje (za pośrednictwem produktów podmiotów
                            trzecich) na Twój telefon o nazwie Logi danych. Logi danych mogą obejmować takie
                            informacje, jak adres IP urządzenia, nazwa urządzenia, wersja systemu operacyjnego,
                            konfiguracja aplikacji podczas korzystania z naszej usługi, godzina i data
                            korzystania z
                            usługi oraz inne statystyki.
                        </p>

                        <h4>Cookies</h4>

                        <p>Pliki cookie to pliki z niewielką ilością danych, które są powszechnie używane jako
                            anonimowe unikalne identyfikatory. Są one wysyłane do przeglądarki z odwiedzanych
                            stron internetowych i są przechowywane w pamięci wewnętrznej urządzenia.</p>

                        <p>Ta usługa nie wykorzystuje tych „plików cookie” w sposób jawny. Jednak aplikacja
                            może wykorzystywać kod i biblioteki stron trzecich, które używają plików „cookie” do
                            gromadzenia informacji i ulepszania swoich usług. Możesz zaakceptować lub odrzucić
                            te pliki cookie i wiedzieć, kiedy plik cookie jest wysyłany na Twoje urządzenie.
                            Jeśli zdecydujesz się odrzucić nasze pliki cookie, możesz nie być w stanie korzystać
                            z niektórych części tej Usługi.</p>

                        <h4>Dostawcy usług</h4>

                        <p>Możemy zatrudniać firmy zewnętrzne i osoby fizyczne z następujących powodów:</p>

                        <ul>
                            <li>Aby ułatwić naszą usługę;</li>
                            <li>Aby świadczyć usługę w naszym imieniu;</li>
                            <li>Aby wykonać usługi związane z usługą; lub</li>
                            <li>Aby pomóc nam w analizie korzystania z naszej Usługi.</li>
                        </ul>

                        <p>Chcemy poinformować użytkowników tej usługi, że te osoby trzecie mają dostęp do
                            twoich danych osobowych. Powodem jest wykonywanie powierzonych im zadań w naszym
                            imieniu. Są one jednak zobowiązane do nieujawniania ani wykorzystywania informacji
                            do jakichkolwiek innych celów.</p>

                        <h4>Bezpieczeństwo</h4>

                        <p>
                            Cenimy Twoje zaufanie do przekazywania nam Twoich danych osobowych, dlatego staramy
                            się wykorzystywać komercyjnie akceptowalne sposoby ich ochrony. Pamiętaj jednak, że
                            żadna metoda transmisji przez Internet ani metoda elektronicznego przechowywania nie
                            jest w 100% bezpieczna i niezawodna i nie możemy zagwarantować jej absolutnego
                            bezpieczeństwa.</p>

                        <h4>Linki do innych stron</h4>

                        <p>Ta usługa może zawierać linki do innych stron. Jeśli klikniesz link innej firmy,
                            nastąpi przekierowanie do tej witryny. Pamiętaj, że te zewnętrzne strony nie są
                            przez nas obsługiwane. Dlatego zdecydowanie zalecamy zapoznanie się z polityką
                            prywatności tych witryn. Nie mamy kontroli i nie ponosimy żadnej odpowiedzialności
                            za treść, politykę prywatności lub praktyki stron lub usług stron trzecich.</p>

                        <h4>Prywatność dzieci</h4>

                        <p>Usługi te nie są skierowane do osób w wieku poniżej 13 lat. Świadomie nie zbieramy
                            danych osobowych od dzieci poniżej 13 lat. W przypadku, gdy odkryjemy, że dziecko
                            poniżej 13 roku życia przekazało nam dane osobowe, natychmiast usuwamy je z naszych
                            serwerów. Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje dziecko przekazało
                            nam dane osobowe, skontaktuj się z nami, abyśmy mogli podjąć niezbędne
                            działania.</p>

                        <h4>Zmiany w niniejszej polityce prywatności</h4>

                        <p>
                            Od czasu do czasu możemy aktualizować naszą Politykę prywatności. Dlatego zaleca się
                            okresowe przeglądanie tej strony pod kątem wszelkich zmian. Powiadomimy Cię o
                            wszelkich zmianach, publikując nową Politykę prywatności na tej stronie. Zmiany
                            obowiązują natychmiast po ich opublikowaniu na tej stronie.</p>

                        <h4>Kontakt</h4>

                        <p>Jeśli masz pytania lub sugestie dotyczące naszej Polityki prywatności, skontaktuj się
                            z nami pod adresem <a href="mailto:biuro@loggy.pl.">biuro@loggy.pl.</a></p>
                    </div>

                </div>
            </Layout>

        )
    }
}
